/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
}
section {
  height: auto;
  width: "100%";
  /* margin-top: 50%; */
}
.search_section {
  margin-top: 1%;
}
.abtn_wrap-slider {
  margin-top: 24vh;
  position: relative;
  z-index: 99999999;
}
.buttonContain {
  position: relative;
  top: 45vh;
  width: 100%;
  left: 40%;
  align-items: center;
  justify-content: center;
  z-index: 400;
}
.feature_section {
  padding: 73px 0px;
}
.mb_30 {
  margin-bottom: 30px;
}
.main_menu a {
  color: #007bff !important;
}
.main_menu .submenu a {
  color: black !important;
}
.main_menu a:hover {
  color: #ea001e !important;
}
.feature_vehicle_item .info_list {
  flex-wrap: nowrap;
}
/* .search_section {
  margin-top: -50%;
} */
/* section.search_section.clearfix,
section.feature_section {
  transform: translate(0px, -41px);
} */
.baseLayout {
  max-width: 100%;
  overflow: hidden;
}
.bg-black {
  background-color: black;
  width: 100%;
  height: 100%;
}
section.gallery_section.sec_ptb_100.clearfix {
  padding-bottom: 9vh;
}
section.slider_section.text-white.text-center.position-relative {
  width: 100%;
  height: 100%;
}
.overlaySlider {
  background-image: linear-gradient(
    0deg,
    rgba(41, 45, 69, 0.8),
    rgba(12, 12, 15, 0.1)
  );
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 450;
  align-items: center;
  justify-content: center;
}
.textSlider {
  position: absolute;
  top: 45%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
/* .abtn_wrap {
  position: relative;
  bottom: -86px;
} */
button a {
  color: #ffffff !important;
  text-decoration: none;
  background-color: transparent;
}

.feature_vehicle_filter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.newsletter_form1 {
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.contentForm {
  max-width: 90%;
}
.buttonGroup {
  max-width: 90%;
}
.slidPict {
  margin-top: 4vh;
}
.container.searchComponent {
  padding: 20px;
  background: rgb(22, 24, 41);
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.sb_widget .slider-parent .buble {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.orderButton {
  transition: all 0.8s 0.2s ease-in-out;
}

@media screen and (max-width: 600px) {
  .modalSynchro__content {
    max-width: 100% !important;
  }
  .modalSynchroContain {
    transform: translate(-26px, 0px);
  }
  .modal__footer {
    text-align: center !important;
  }
  .historyList {
    padding-top: 0px !important;
  }
  .flex-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
    padding-left: 20px;
  }
  .checkboxImg {
    transform: scale(1.07) translate(16px, 8px) !important;
    margin-bottom: 25px;
  }
  .basketImg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .payment-method-name {
    transform: scale(1.3) translate(26px, 0);
  }
  .viewIcon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 3% !important;
    right: 6% !important;
    z-index: 8;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    padding: 3px !important;
    opacity: 1 !important;
  }
  .ribbon-top-left {
    top: -10px !important;
    left: 5px !important;
  }

  .ribbon > span > p {
    transform: translate(10px, -5px) !important;
  }
  .image_wrap svg {
    opacity: 1 !important;
    visibility: visible !important;
    transition: all 0.3s ease;
    transform: translate(0, 10px) !important;
  }
  .textSlider {
    width: 100% !important;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -25px);
  }
  .sliderTextCenter {
    width: 94%;
    text-align: center;
  }
  .btnReserveDetail {
    margin-top: 0 !important;
  }
  .header_action_btns button {
    margin-left: 11px;
  }
  .add-compare-unlinkable {
    transform: translate(0, -18px);
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  .add-compare-unlinkable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-compare-unlinkable .add-to-compare-empty {
    width: 70% !important;
  }
  .add-compare-unlinkable .add-to-compare-empty p {
    font-size: 8px !important;
  }
  .floatingButton {
    width: 92% !important;
  }
  .slidPict {
    width: 100% !important;
    height: 456px !important;
    object-fit: cover;
  }
  .buttonContain {
    position: relative;
    top: 10vh !important;
    width: 100%;
    left: 15% !important;
    align-items: center;
    justify-content: center;
    z-index: 400;
  }
  .abtn_wrap-slider {
    margin-top: 34vh !important;
    position: relative;
    z-index: 400;
  }
  .section_title .title_text span {
    font-size: 27px !important ;
  }
  button.custom_btn.bg_default_red.text-uppercase {
    margin-left: 10vw;
    margin-top: 9vh;
  }
  .feature_vehicle_filter {
    margin-left: 2px;
  }
  .feature_section {
    min-height: auto;
  }
  .item_content.text-white {
    top: 123px !important;
    left: -19px;
  }
  .feature_fullimage:before {
    display: none;
  }
  .subscribeButton {
    margin-left: 0 !important;
    margin-top: 2vh;
  }
  .searchButton {
    margin-left: 0 !important;
  }
  .feature_vehicle_item .info_list li {
    font-size: 12px !important;
    padding: 8px 6px !important;
  }
  .reservationButton,
  .detailsButton,
  .slideDetailsCarButton {
    margin-left: 0vw !important;
  }
  .car_choose_carousel .item > img,
  .car_choose_carousel .btns_group li .custom_btn {
    width: 50% !important;
  }
  .car_choose_carousel .item_title {
    font-size: 24px !important;
  }
  .mini {
    height: 81px !important;
    overflow: hidden;
    transition: all 0.8s ease-in-out;
  }
  .maxi {
    height: inherit;
    transition: all 0.8s ease-in-out;
  }
  /* .error_text_m {
    transform: scale(0.5) translate(-100px, 0);
  } */
  .error_text_m {
    transform: scale(0.5) translate(-100px, 0) rotate3d(0, 5, 4.5, 0.142rad);
    transform-style: preserve-3d;
    transform-origin: center;
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
  }
  .error_text_m h2 {
    font-size: 189px !important;
    transform: translate(84px, 0) rotate3d(0, 5, 4.5, -0.142rad);
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
  }
  .error_icon i {
    font-size: 85px !important;
  }
  .error_msg_div {
    float: left;
    width: 71%;
    padding-top: 15px;
  }
}

.cell-loader {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 20px;
  display: table;
}

.card {
  background: white;
  border: 1px solid #c3c6cf;
  border-radius: 15px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  height: 200px;
}
.formCard {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px 1px rgb(108 108 108 / 15%);
  padding: 15px;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px 1px rgb(108 108 108 / 15%);
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:hover + label:before {
  opacity: 0.5;
}

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: #111;
  font-weight: 600;
  transform: scale(0.92);
}

.checkbox:before {
  z-index: 15;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 20px;
  border-width: 4px;
  border-style: solid;
  border-color: #444;
  height: 20px;
}
input[type="checkbox"]:checked + label {
  color: #000;
}

input[type="checkbox"]:checked + label:before {
  border-color: transparent;
  border-left-color: #2ecc71;
  border-bottom-color: #2ecc71;
  transform: rotate(-50deg);
  width: 22px;
  height: 12px;
  top: 3px;
}
.feature_vehicle_item.mt-0.ml-0.aos-init.aos-animate {
  transform: scale(0.98);
}

.choiceButton.bg_default_red {
  position: absolute;
  bottom: 6%;
  right: 3%;
  color: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 11px black;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
  background: #0048b9;
}

.choiceButton.bg_default_red:hover {
  font-size: 22px;
  transition: all 0.8s ease-in-out;
}
img.bnt-img {
  transform: translate(0px, -6.2vh);
}
.contactArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* EMPTY CART ANIMATION */

* {
  margin: 0;
  padding: 0;
}
/* .hero {
  overflow: hidden;
  height: 100vh;
  background: linear-gradient(
    180deg,
    #09283d,
    #1b415c,
    #29516c,
    #6e8ea5,
    #7cadd0,
    #7cadd0,
    #7cadd0,
    #7cadd0,
    #6e8ea5,
    #3a6583,
    #1a4461,
    #09283d
  );
  background-size: 2400% 2400%;
  animation: dayNight 60s ease infinite;
} */

.hero {
  height: 61vh;
  width: 100%;
  background: linear-gradient(
    180deg,
    #09283d,
    #1b415c,
    #29516c,
    #6e8ea5,
    #7cadd0,
    #7cadd0,
    #7cadd0,
    #7cadd0,
    #6e8ea5,
    #3a6583,
    #1a4461,
    #09283d
  );

  position: relative;
  overflow: hidden;
}
/* @keyframes dayNight {
  0% {
    background-position: 25% 0%;
  }
  50% {
    background-position: 76% 100%;
  }
  100% {
    background-position: 25% 0%;
  }
} */
/* .moon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgb(207, 207, 212);
  margin: auto;
  box-shadow: 0 0 60px gold, 0 0 100px rgb(185, 160, 24),
    inset 0 5px 12px 26px #f5f5f5, inset -2px 8px 15px 36px #e6e6db;
  transition: 1s;
  transition: 1s;
  left: 55%;
  top: 30px;
  position: absolute;
  animation: sun-moon 40s 2s linear infinite;
  transform-origin: 50% 500px;
  transform: translate(-50vh 0);
} */

/* @keyframes moon {
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translate(80px, -140px);
  }
}
@keyframes sun-moon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.highway {
  height: 200px;
  width: 500%;
  display: block;
  background-image: url("./assets/images/emptyCart/56495798-asphalt-highway-road-marks-top-view.jpg");
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-repeat: repeat-x;
  animation: highway 5s linear infinite;
}
@keyframes highway {
  100% {
    transform: translateX(-3400px);
  }
}

.car {
  width: 500px;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}
.car img {
  width: 100%;
  animation: car 1s linear infinite;
}
@keyframes car {
  100% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(-1px);
  }
  0% {
    transform: translateY(-1px);
  }
}
.wheel {
  left: 50%;
  bottom: 179px;
  transform: translateX(0%);
  position: absolute;
  z-index: 2;
  width: 6%;
}
.wheel img {
  width: 72px;
  height: 72px;
  animation: wheel 1s linear infinite;
}
.back-wheel {
  left: -165px;
  position: absolute;
}
.front-wheel {
  left: 90px;
  position: absolute;
}
@keyframes wheel {
  100% {
    transform: rotate(360deg);
  }
}

/* SLIDER RANGE INPUT */

.slider-parent {
  position: relative;
}
.buble {
  position: absolute;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 21px;
  margin-left: -26px;
  margin-top: 11px;
  padding: 8px;
  border: 2px solid white;
}
.slider {
  width: 100%;
  margin: 50% auto;
}

input[type="range"] {
  -webkit-appearance: none !important;
  width: 100%;
  height: 15px;
  background-color: #ffffff;
  border: 1px solid #97c68b;
  border-radius: 10px;
  margin: auto;
  transition: all 0.3s ease;
}
input[type="range"]:hover {
  background-color: rgba(248, 152, 38, 0.55);
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 20px;
  height: 20px;
  background-color: #ea001e;
  border-radius: 30px;
  box-shadow: 0px 0px 3px #3c6d59;
  transition: all 0.5s ease;
}
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #f89826;
}
input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: 0px 0px 1px #f89826;
}

#rangevalue {
  text-align: center;
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  display: block;
  margin: auto;
  padding: 10px 0px;
  width: 100%;
  color: rgba(248, 152, 38, 0.55);
}
.price-range-area.clearfix {
  transform: translate(0px, -17px);
}

.slider-parent {
  position: relative;
  margin-top: 51px;
}
.sliderContent {
  transform: translate(0px, 40px);
}

/*ONLOAD PAGE ANIMATIONS LOGIC */
[data-inviewport="fadeInUp"].is-inViewport {
  animation: 1s fadeInUp 0.2s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="fadeInUp"]:not(.is-inViewport) {
  transition: 1s;
  opacity: 0;
}
[data-inviewport="scaleUp"].is-inViewport {
  animation: scaleUp 1s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="scaleUp"]:not(.is-inViewport) {
  transition: 1s;
  opacity: 0;
}

[data-inviewport="fadeInUpMediumDelay"]:not(.is-inViewport) {
  opacity: 0;
  transition: 1s;
}
[data-inviewport="fadeInUpMediumDelay"].is-inViewport {
  animation: 1s fadeInUp 0.5s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="fadeInUpLargeDelay"]:not(.is-inViewport) {
  opacity: 0;
  transition: 1s;
}
[data-inviewport="fadeInUpLargeDelay"].is-inViewport {
  animation: 1s fadeInUp 0.8s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="fadeInUpVeryLargeDelay"]:not(.is-inViewport) {
  opacity: 0;
  transition: 1s;
}
[data-inviewport="fadeInUpVeryLargeDelay"].is-inViewport {
  animation: 1s fadeInUp 1.1s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes scaleUp {
  0% {
    transform: translateX(0) scale(0);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.filterText {
  margin-top: 17px;
}
.form_item .nice-select:after {
  display: none;
}

.suggestionContainer {
  position: absolute;
  top: 6vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  padding: 4px;
  border-radius: 0 0 6px 6px;
  border: 13px solid rgba(255, 255, 255, 0.1);
}

.suggestionContainer .suggestions {
  margin-top: 1vh;
}
.suggestionContainer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
}
.suggestionContainer .suggestions li {
  font-size: 1.5vmin;
  list-style: none;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
  padding: 4px;
  cursor: pointer;
}
.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.gallery_fullimage {
  position: relative;
  z-index: 10;
}

.gallery_fullimage:hover .overlay,
.gallery_fullimage_2:hover .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  transition: all 1s ease-in;
}

.error_404 {
  padding-bottom: 60px;
}
.error_text_m {
  position: relative;
  padding: 50px 0;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.error_text_m h2 {
  font-size: 232px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin: 0 auto 15px 52px;
}
.error_text_m h2 span {
  color: #111;
  font-weight: 900;
}
.background_icon {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.background_icon i {
  color: #e7e7e7;
  font-size: 538px;
  line-height: 400px;
  vertical-align: top;
}
.error_icon {
  float: left;
  width: 29%;
  padding-top: 15px;
}
.error_icon i {
  color: #cfcfcf;
  font-size: 130px;
}
.fa-smile-o:before {
  content: "\f118";
}
.error_msg_div {
  float: left;
  width: 71%;
  padding-top: 15px;
}
.error_msg_div h3 {
  font-size: 60px;
  font-family: "Lato", sans-serif;
}
.error_msg_div h3 span {
  font-size: 40px;
  display: block;
}
.error_msg_div p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 15px;
}
.error_msg_div .btn {
  margin-top: 30px;
  background: #fa2837 none repeat scroll 0 0 !important;
  fill: #fa2837;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
  line-height: 30px;
  margin: auto;
  padding: 7px 25px;
  transition: all 0.3s linear 0s;
}
.error_msg_div .btn .angle_arrow .fa {
  /* display: block; */
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  vertical-align: middle;
}
.angle_arrow {
  background: #fff none repeat scroll 0 0;
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  margin-left: 4px;
  text-align: center;
  vertical-align: text-top;
  width: 22px;
}
.fa-angle-right {
  color: #fa2837;
  fill: #fa2837;
}
.fa-angle-right:before {
  content: "\f105";
}

/* COMPAR SECTION STYLES */
.vc_custom_1466145513210 {
  margin-top: 30px !important;
}
.vc_row {
  margin-left: -15px;
  margin-right: -15px;
}
.vc_row:after,
.vc_row:before {
  content: " ";
  display: table;
}
.vc_column_container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .vc_col-sm-12 {
    width: 100%;
  }
}
.vc_column_container > .vc_column-inner {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.vc_column_container > .vc_column-inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.vc_column-inner::after,
.vc_column-inner::before {
  content: " ";
  display: table;
}
.wpb_column > .vc_column-inner > .wpb_wrapper > :last-child {
  margin-bottom: 0;
}
.stm-template-car_dealer_two .container {
  max-width: 1140px;
}
.stm-car-compare-row {
  margin-top: 34px;
}
.stm-template-car_dealer_two h2,
.stm-template-car_dealer_two .h2 {
  letter-spacing: -1px;
}
.compare-title {
  margin-bottom: 25px;
  line-height: 36px;
  margin-top: 30px;
  text-transform: uppercase;
}
.colored-separator.text-left {
  text-align: left;
}
.colored-separator {
  text-align: center;
  vertical-align: top;
  font-size: 0;
}
.colored-separator .first-long,
.colored-separator .last-short {
  display: inline-block;
  vertical-align: top;
  width: 33px;
  height: 5px;
  transform: skew(-40deg, 0deg);
  border-radius: 2px;
  background-color: #ffb126;
}
.colored-separator .first-long {
  margin-right: 3px;
}
.colored-separator .last-short {
  width: 16px;
}
.car-listing-row .compare-col-stm {
  margin-bottom: 6px;
}
.rmv_txt_drctn {
  color: inherit;
  text-decoration: none !important;
}
.stm-prev-next,
.stm-prev-next i,
.stm-featured-boats-widget .stm-featured-boats-w-units .content .title,
.stm-boats-data .single-boat-data-units .stm-gallery-action-unit,
.stm-template-boats .boats-image-unit,
.stm-template-front-loop .image:after,
.stm-isotope-listing-item .image:after,
.boats-image-unit > div,
.stm-compare-directory-new,
.stm-filter-sidebar-boats #stm-classic-filter-submit,
.stm-boats-shorten-filter,
.stm-filter-sidebar-boats .form-group,
.testimonials-carousel-boats .owl-dots .owl-dot span,
.stm-boats-video-poster,
.stm-boats-video-iframe .stm-boats-video-poster:before,
.stm-boats-listing-icons > a .name,
.stm-layout-box-boats .boat-line,
.stm-layout-box-boats:after,
.stm-layout-box-boats:before,
.stm-boats-news .stm-post-content .title,
#stm-boats-header
  #header
  .header-inner-content
  .listing-service-right
  .listing-menu
  li
  a:after,
#stm-boats-header .lOffer-compare .heading-font,
#stm-boats-header #top-bar .language-switcher-unit .stm_current_language,
#stm-boats-header #top-bar .top-bar-info li,
.stm-user-mobile-info-wrapper,
.listing-menu-mobile-wrapper .stm-opened-menu-listing,
.listing-menu-mobile-wrapper
  .listing-menu-mobile
  > li
  > a
  .stm_frst_lvl_trigger,
.listing-menu-mobile-wrapper
  .listing-menu-mobile
  > li
  > a
  .stm_frst_lvl_trigger:before,
.listing-menu-mobile-wrapper
  .listing-menu-mobile
  > li
  > a
  .stm_frst_lvl_trigger:after,
.listing-menu ul,
.stm-write-dealer-review .right .stm-star-rating[data-width="0"] span,
.stm_add_car_form .stm-form-checking-user .stm-form-inner:before,
.stm_add_car_form .stm-form-checking-user .stm-form-inner .stm-icon-load1,
.stm-history-popup,
.stm_add_car_form
  .stm_add_car_form_1
  .stm-form-1-end-unit
  .stm-form-1-quarter
  .stm-label
  i,
.stm-delete-confirmation-overlay,
.stm-delete-confirmation-popup,
.listing-list-loop-edit,
.stm_edit_disable_car,
.stm-directory-grid-loop .image .stm-listing-compare,
.stm-directory-grid-loop .image .stm-listing-favorite,
.stm-user-private .stm-user-private-sidebar .stm-actions-list a i,
.stm-listing-loader,
.stm-pricing-table__action,
.stm-pricing__filters .stm-pricing__filter_fluid-hover,
.stm-listing-car-gallery .stm-car-medias > div,
.stm-listing-directory-checkboxes .stm-accordion-content-wrapper.loading:before,
.stm-listing-directory-checkboxes .stm-accordion-content-wrapper.loading:after,
.stm-listing-directory-checkboxes .stm-option-label .stm-option-image,
.listing-list-loop.stm-listing-directory-list-loop .stm-car-medias > div,
.listing-list-loop.stm-listing-directory-list-loop .stm-listing-compare,
.listing-list-loop.stm-listing-directory-list-loop .stm-listing-favorite,
.listing-list-loop.stm-listing-directory-list-loop .stm-car-medias,
.stm-template-listing
  .archive-listing-page
  .listing-list-loop
  .image
  .image-inner:after,
.stm_dynamic_listing_filter .stm_dynamic_listing_filter_nav li,
.lOffer-account-unit .lOffer-account-dropdown .logout .fa,
.stm-settings-icon,
.lOffer-account-dropdown,
.stm_listing_tabs_style_2 .stm_listing_nav_list li a:after,
.stm_listing_icon_filter .stm_listing_icon_filter_single .inner .image,
.stm_icon_filter_label,
.stm_listing_icon_filter .stm_listing_icon_filter_single .inner img,
.header-listing .listing_add_cart > div,
.stm-site-preloader:after,
.stm-site-preloader:before,
.header-service .bloglogo img,
.service-mobile-menu-trigger span,
.header-service,
.stm-image-links .stm-image-link .inner img,
.stm-filter-chosen-units-list,
.stm-ajax-row:before,
.stm-ajax-row:after,
.stm-sell-a-car-form .form-navigation .form-navigation-unit .number,
.stm-sell-a-car-form
  .form-navigation
  .form-navigation-unit.validated
  .number:before,
#animated-blog-wrapper .title,
.vc_toggle .vc_toggle_icon:after,
.mobile-menu-holder .header-menu > li .arrow i,
.header-help-bar-trigger i,
.media-carousel-item .stm_fancybox:after,
.select2-results__options li,
.modern-filter-badges .stm-filter-chosen-units-list,
.stm-modern-filter-unit-images .stm-single-unit-image .image,
.product-title,
.stm-shop-sidebar-area .widget > ul > li > a:hover:before,
.post-grid-single-unit .title,
.widget_archive
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after,
#main .widget_search form.search-form:before,
.stm-accordion-single-unit > a.title h5,
.stm-accordion-single-unit > a.title .minus,
.stm-accordion-single-unit > a.title .minus:after,
.certified-logo-1,
.certified-logo-2,
.listing-list-loop .title,
.icon-box .icon-text .content span,
.icon-box .icon-text .content p,
.stm-compare-car-img:after,
.compare-col-stm-empty .image img,
.remove-from-compare,
.stm-call-to-action-1 .stm-call-action-right .stm-call-action-right-banner,
.stm-call-action-right-banner,
.compare-col-stm-empty .h5,
.compare-col-stm-empty > a:hover .h5,
.compare-col-stm-empty .image .stm-icon-car_search,
.compare-col-stm-empty .image:hover .stm-icon-car_search,
.brands-carousel-item-inner,
.single-add-to-compare,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
.wpb_tour_tabs_wrapper.ui-tabs ul.wpb_tabs_nav > li > a:before,
.vc_tta-container
  .vc_tta-tabs
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  .vc_tta-tab:before,
.stm-thumbs-car-gallery .owl-item:after,
.modal-open #wrapper,
.team-info,
.stm-our-team .image:before,
.stm-our-team .meta,
.stm-our-team .meta .name,
.stm-our-team .meta .position,
.owl-prev,
.owl-next,
.vc_tta-icon,
.load-more-btn,
.car-listing-actions,
.stm-owl-next,
.stm-owl-prev,
.icon-box-bottom-triangle,
.icon-box:before,
.home-slider .tp-bullet,
.fancy-iframe,
a,
.ajax-loader,
.global-alerts,
.stm-ajax-row,
.stm-ajax-row:before,
.calculator-alert {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.compare-col-stm-empty .image {
  position: relative;
}
.car-listing-row .image,
.listing-car-item-inner .image {
  overflow: hidden;
  position: relative;
}
.car-listing-row .image:before,
.listing-car-item-inner .image:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease;
  transform: translateZ(0);
  opacity: 0;
}
.stm-compare-car-img {
  position: relative;
}
.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.stm-compare-car-img:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.remove-compare-unlinkable {
  position: relative;
  height: 29px;
  margin-top: -29px;
  z-index: 20;
}
.remove-from-compare {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -78px;
  width: 157px;
  padding: 0 6px 0 32px;
  height: 29px;
  line-height: 29px;
  text-transform: uppercase;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  background-color: #ea001e;
  opacity: 0;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.single-car-actions > ul li {
  float: left;
  margin-right: 13px;
  margin-bottom: 12px;
}
.add-compare-unlinkable .add-to-compare-empty {
  position: relative;
  display: block;
  background-color: #4971fe;
  color: #fff;
  border-color: #4971fe;
  width: 100%;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
  text-transform: uppercase;
  padding: 0 15px;
  border: 1px solid #ddd;
  border-radius: 15px;
  height: 31px;
}
.add-compare-unlinkable .add-to-compare-empty p {
  text-align: center;
  padding: 4px;
  font-size: 9px;
  transform: translate(0, 5px);
}
.buttonAddCarToCompare {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.buttonAddCarToCompare .add-compare-unlinkable {
  transform: translate(0, -30px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.buttonAddCarToCompare:hover .add-compare-unlinkable {
  transform: translate(0, -10px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.car-action-unit.add-to-compare {
  padding-right: 0;

  white-space: nowrap;
}
.car-action-unit.add-to-compare {
  padding-right: 10px;
  padding-left: 12px;
  min-width: 158px;
  width: fit-content;
  white-space: nowrap;
  filter: drop-shadow(-1px 2px 3px rgba(50, 50, 0, 0.25));
}
.add-to-compare {
  position: relative;
  display: block;
  padding: 0 15px;
  padding-left: 62px;
  font-size: 10px;
  text-transform: uppercase;
  color: #888 !important;
  border: 1px solid #ddd;
  line-height: 27px;
  border-radius: 15px;
  text-decoration: none !important;
}

.stm-template-car_dealer_two.no_margin
  #wrapper
  #main
  ul.list-style-2
  li:before {
  color: #ffb126;
}
ul.list-style-2 li:before {
  position: absolute;
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  left: 3px;
  top: 0;
  content: "\f058";
  font-size: 14px;
  color: #ffb126;
}
.stm-compare-row {
  margin-bottom: 30px;
}
.stm-car-compare-row {
  margin-top: 34px;
}
.row-4 .col-md-3:nth-child(4n + 1) {
  clear: both;
}
.compare-values table > tbody tr td .h5 {
  margin: 0;
  font-weight: 700;
  color: #232628;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: normal;
  letter-spacing: 0px;
  word-spacing: 0px;
}

li {
  list-style: none;
}
.compareButton {
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.stm-template-car_dealer_two.inventory-dark:not(.home)
  #wrapper
  #main
  .container
  .archive-listing-page
  .listing-list-loop.stm-listing-directory-list-loop
  .content
  .meta-bottom
  .single-car-actions
  ul
  li
  a
  i {
  color: #ffb126;
}
.stm-template-car_dealer_two.no_margin
  #wrapper
  #main
  .archive-listing-page
  .listing-list-loop.stm-listing-directory-list-loop
  .content
  .meta-bottom
  .single-car-actions
  ul
  li
  a
  i {
  color: #ffb126;
}
.car-action-unit.add-to-compare > i {
  font-size: 14px;
}
.car-action-unit > i {
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -1px;
  font-size: 17px;
  color: #4971fe;
  transform: translateY(-50%);
}
.floatingButton {
  position: fixed;
  top: 15vh;
  width: 60%;
  background-color: #0048b9;
  z-index: 900;
  border-radius: 4px;
  box-shadow: 0px 1px 6px 1px #6c6c6c26;
}
.floatingButton a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.carIcon {
  width: 102px;
  height: 79px;
  position: absolute;
  top: 28px;
  left: 66px;
  z-index: 50;
  opacity: 0.5;
  transition: all 0.8s ease-in-out;
  transform: scale(0.9);
}
.image:hover .carIcon {
  opacity: 1;
  transition: all 0.8s ease-in-out;
  transform: scale(1);
}

.overlay-choosedCar {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}

/* EMPTY CART MOBILE */
.empty-cart {
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  padding-bottom: 50px;
}
.empty-cart svg {
  max-width: 90%;
  padding: 2rem 3rem;
  margin-bottom: 2vh;
}
svg #oval,
svg #plus,
svg #diamond,
svg #bubble-rounded {
  -webkit-animation: plopp 4s ease-out infinite;
  animation: plopp 4s ease-out infinite;
}
svg #oval:nth-child(1),
svg #plus:nth-child(1),
svg #diamond:nth-child(1),
svg #bubble-rounded:nth-child(1) {
  -webkit-animation-delay: -240ms;
  animation-delay: -240ms;
}
svg #oval:nth-child(2),
svg #plus:nth-child(2),
svg #diamond:nth-child(2),
svg #bubble-rounded:nth-child(2) {
  -webkit-animation-delay: -480ms;
  animation-delay: -480ms;
}
svg #oval:nth-child(3),
svg #plus:nth-child(3),
svg #diamond:nth-child(3),
svg #bubble-rounded:nth-child(3) {
  -webkit-animation-delay: -720ms;
  animation-delay: -720ms;
}
svg #oval:nth-child(4),
svg #plus:nth-child(4),
svg #diamond:nth-child(4),
svg #bubble-rounded:nth-child(4) {
  -webkit-animation-delay: -960ms;
  animation-delay: -960ms;
}
svg #oval:nth-child(5),
svg #plus:nth-child(5),
svg #diamond:nth-child(5),
svg #bubble-rounded:nth-child(5) {
  -webkit-animation-delay: -1200ms;
  animation-delay: -1200ms;
}
svg #oval:nth-child(6),
svg #plus:nth-child(6),
svg #diamond:nth-child(6),
svg #bubble-rounded:nth-child(6) {
  -webkit-animation-delay: -1440ms;
  animation-delay: -1440ms;
}
svg #oval:nth-child(7),
svg #plus:nth-child(7),
svg #diamond:nth-child(7),
svg #bubble-rounded:nth-child(7) {
  -webkit-animation-delay: -1680ms;
  animation-delay: -1680ms;
}
svg #oval:nth-child(8),
svg #plus:nth-child(8),
svg #diamond:nth-child(8),
svg #bubble-rounded:nth-child(8) {
  -webkit-animation-delay: -1920ms;
  animation-delay: -1920ms;
}
svg #oval:nth-child(9),
svg #plus:nth-child(9),
svg #diamond:nth-child(9),
svg #bubble-rounded:nth-child(9) {
  -webkit-animation-delay: -2160ms;
  animation-delay: -2160ms;
}
svg #oval:nth-child(10),
svg #plus:nth-child(10),
svg #diamond:nth-child(10),
svg #bubble-rounded:nth-child(10) {
  -webkit-animation-delay: -2400ms;
  animation-delay: -2400ms;
}
svg #oval:nth-child(11),
svg #plus:nth-child(11),
svg #diamond:nth-child(11),
svg #bubble-rounded:nth-child(11) {
  -webkit-animation-delay: -2640ms;
  animation-delay: -2640ms;
}
svg #oval:nth-child(12),
svg #plus:nth-child(12),
svg #diamond:nth-child(12),
svg #bubble-rounded:nth-child(12) {
  -webkit-animation-delay: -2880ms;
  animation-delay: -2880ms;
}
svg #oval:nth-child(13),
svg #plus:nth-child(13),
svg #diamond:nth-child(13),
svg #bubble-rounded:nth-child(13) {
  -webkit-animation-delay: -3120ms;
  animation-delay: -3120ms;
}
svg #oval:nth-child(14),
svg #plus:nth-child(14),
svg #diamond:nth-child(14),
svg #bubble-rounded:nth-child(14) {
  -webkit-animation-delay: -3360ms;
  animation-delay: -3360ms;
}
svg #oval:nth-child(15),
svg #plus:nth-child(15),
svg #diamond:nth-child(15),
svg #bubble-rounded:nth-child(15) {
  -webkit-animation-delay: -3600ms;
  animation-delay: -3600ms;
}
svg #oval:nth-child(16),
svg #plus:nth-child(16),
svg #diamond:nth-child(16),
svg #bubble-rounded:nth-child(16) {
  -webkit-animation-delay: -3840ms;
  animation-delay: -3840ms;
}
svg #bg-line:nth-child(2) {
  fill-opacity: 0.3;
}
svg #bg-line:nth-child(3) {
  fill-opacity: 0.4;
}

@-webkit-keyframes plopp {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -10px);
    opacity: 0;
  }
}
@keyframes plopp {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -10px);
    opacity: 0;
  }
}

/* PLACEHOLDER DATE INPUT COLOR */
.empty {
  color: rgb(209, 14, 14);
}

.overlay-reservationForm {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.pictContainer:hover #myImg {
  opacity: 0.7 !important;
}

.pictContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
span#mySpan {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#mySpan svg {
  margin-left: 5vw;
  margin-top: 4.5vh;
}
.pictContainer:hover #myImg {
  transform: scale(1.05);
}
.icon svg {
  transform: translate3d(0, -60px, 0);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  fill: #ffffff;
  /* position: absolute; */
  /* top: 46%;
  left: 46%; */
  cursor: pointer;
}

.pictContainer:hover .icon svg {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
  font-size: 36px;
}

.pictContainer svg:hover {
  fill: #0048b9;
  transition: all 1.7s ease;
}
.image_wrap svg {
  transform: translate3d(0, -60px, 0);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 100px;
  padding: 4px;
  box-shadow: 0 2px 6px black;
}
.image_wrap:hover svg {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

/* RIBBON CSS */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 5;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #ee0303;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #ea001e;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 15px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: #5e030f;
  border-left-color: #5e030f;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 33px;
  transform: rotate(-45deg);
}

.viewIcon {
  opacity: 0;
  transform: translate(0, -20px);
  transition: all 0.3s ease-in-out;
}
.stm-compare-car-img:hover .viewIcon {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.3s ease-in-out;
}

.slide-in-fwd-center {
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
.detailsHistory {
  border-top: 1px solid #d8d8d8;
}
.historyList {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px 1px rgb(108 108 108 / 15%);
  padding: 10px;
  /* height: 69px; */
  overflow: hidden;
  transition: all 0.7s ease-in-out;
}

.little {
  opacity: 0;
  overflow: hidden;
  height: 0;
  transition: all 0.4s ease-in-out;
}
.full {
  height: 100%;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.reduceAndLeft {
  transform: translate(-32px, -10px) scale(0.8);
  width: 62%;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.slideFromLeft {
  height: auto;
  transform: translate(0px, 0px) scale(1);
  transition: all 0.4s ease-in-out;
}
.subHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
.subHeaderNormal {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.viewSubHeader {
  text-align: "center";
  width: 20%;
  font-size: 13px !important;
  color: #6e7684 !important;
  transition: all 0.4s ease-in-out;
}
.hideSubHeader {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  display: none !important;
}
.qrCode {
  transform: translate(-20px, 0);
}

/* QR CODE MODAL CSS  */
/* .wrapper a {
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  background-color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  color: #585858;
  font-family: "Roboto", sans-serif;
} */

.modalQrcode {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 5000;
  padding-top: 15vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.4s;
}

.modalQrcode_target {
  visibility: visible !important;
  opacity: 1 !important;
  display: flex !important;
}

.modalQrcode__content {
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  background: #fff;
  padding: 1em 2em;
  align-items: flex-start;
  justify-content: space-evenly;
}
.modalQrcodeHead {
  transform: scale(1.3) translate(16px, -6px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
}
.modalQrcodeHead p {
  border-bottom: 1px solid lightgrey;
}
.modalQrcodeHead button {
  transform: translate(-7px, -13px);
}
.modalQrcodeContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modalQrcode__footer {
  text-align: center;
  margin-top: 14px;
  border-top: 1px solid lightgrey;
}
.modalQrcode__footer a {
  color: #585858;
}
.modalQrcode__footer span {
  color: #d02d2c;
}
.modalQrcode__close {
  color: red;
  font-size: 30px;
}

.historyList li {
  border-top: 1px solid rgba(216, 216, 216, 0.4);
  margin-top: 20px;
}
.vehicleName,
.dateTimeValues {
  text-align: center;
}
.dateTimeValues,
.pricesSection {
  font-weight: 500;
}
.dateTimeTitles,
.daysTitle,
.pricesSection {
  font-size: 16px;
}
.priceList,
.dateTimeList,
.daysNumber {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}
.swing-in-top-fwd {
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

/* ORDER PAYMENT METHOD CSS */
.order-payment-method {
  background-color: #f7f7f7;
  padding: 40px 20px 30px;
}
.single-payment-method {
  margin-bottom: 20px;
}
.custom-checkbox.custom-control,
.custom-radio.custom-control {
  min-height: auto;
  margin-left: 1.5rem;
}
.custom-checkbox input,
.custom-checkbox .custom-control-input,
.custom-radio input,
.custom-radio .custom-control-input {
  outline: none;
  display: none;
}
.single-payment-method label,
.single-payment-method .custom-control-label {
  font-weight: 500;
}
.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
  font-size: 14px;
  line-height: 1;
  padding-left: 10px;
  font-weight: 400;
  display: block;
  cursor: pointer;
  position: relative;
}
.custom-checkbox input:checked ~ .custom-control-label:before,
.custom-checkbox input:checked label:before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-checkbox .custom-control-input:checked label:before,
.custom-radio input:checked ~ .custom-control-label:before,
.custom-radio input:checked label:before,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-radio .custom-control-input:checked label:before {
  background-color: #cc2121;
  border-color: #cc2121;
}
.custom-radio label:before,
.custom-radio .custom-control-label:before {
  border-radius: 50%;
  height: 14px;
  width: 14px;
}
.custom-checkbox .custom-control-label:before,
.custom-radio .custom-control-label:before {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 0;
  height: 15px;
  top: 0;
  width: 15px;
  content: "";
  position: absolute;
  left: -1.5rem;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio label::after,
.custom-radio .custom-control-label::after {
  background-size: 10px 10px;
  left: -22px;
  top: 2px;
  height: 10px;
  width: 10px;
}
.custom-checkbox.custom-control,
.custom-radio.custom-control {
  min-height: auto;
  margin-left: 1.5rem;
}
.single-payment-method:first-child .payment-method-details {
  display: block;
}
.single-payment-method .payment-method-details {
  background-color: #f1f1f1;
  color: #111;
  font-weight: 400;
  font-size: 13px;
  padding: 10px;
  position: relative;
  margin-top: 20px;
  display: none;
}
.single-payment-method .payment-method-details * {
  margin: 0;
}
.single-payment-method .payment-method-details:after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f1f1f1;
  bottom: 100%;
}
.single-payment-method {
  margin-bottom: 20px;
}
.custom-checkbox input,
.custom-checkbox .custom-control-input,
.custom-radio input,
.custom-radio .custom-control-input {
  outline: none;
  display: none;
}
.single-payment-method label,
.single-payment-method .custom-control-label {
  font-weight: 500;
}
.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
  font-size: 14px;
  line-height: 1;
  padding-left: 10px;
  font-weight: 400;
  display: block;
  cursor: pointer;
  position: relative;
}
.single-payment-method .paypal-card {
  max-width: 150px;
  height: 50px;
  margin-top: 10px;
  display: block;
}
.custom-radio label:before,
.custom-radio .custom-control-label:before {
  border-radius: 50%;
  height: 14px;
  width: 14px;
}
.scale-in-ver-center {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(12px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.flex-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}
.vehicleName {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.vehicleNameContent {
  margin-top: 20px;
}
.validity {
  transform: translate(9px, -25px) scale(1.5);
}

.form_item input + span {
  padding-right: 30px;
}

.form_item input:invalid + span:after {
  position: absolute;
  content: "✖";
  color: red;
  padding-left: 5px;
}

.form_item input:valid + span:after {
  position: absolute;
  content: "✓";
  color: green;
  padding-left: 5px;
}

.validity_weekDay {
  transform: translate(17px, -25px) scale(1.5);
  position: absolute;
  content: "✓";
  color: green;
  padding-left: 5px;
  padding-right: 30px;
}
.validity_notWeekDay {
  transform: translate(17px, -25px) scale(1.5);
  position: absolute;
  content: "✖";
  color: red;
  padding-left: 5px;
  padding-right: 30px;
}

/* SYNCHRO ACCOUNT CSS */
.bodySync {
  background: #fdfdfd;
  font-family: Assistant, sans-serif;
  display: flex;
  min-height: 90vh;
}
.login {
  color: white;
  background: #0aa5dd;
  background: linear-gradient(to right, #3a3a39, #1c1d1d);
  margin: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), 0px 10px 20px rgba(0, 0, 0, 0.3),
    0px 30px 60px 1px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 50px;
  margin-top: -15%;
}
.login .head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .head .company {
  font-size: 2.2em;
  color: white;
  width: 300px;
  text-align: center;
}
.login .msg {
  text-align: center;
}
.login .form input[type="text"].text {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px white;
  width: 100%;
  color: white;
  font-size: 1em;
  outline: none;
}
.login .form .text::placeholder {
  color: #d3d3d3;
}
.login .form input[type="password"].password {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px white;
  width: 100%;
  color: white;
  font-size: 1em;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}
.login .form .password::placeholder {
  color: #d3d3d3;
}
.login .form .btn-login {
  background: none;
  text-decoration: none;
  color: white;
  box-shadow: 0px 0px 0px 2px white;
  border-radius: 3px;
  padding: 5px 2em;
  transition: 0.5s;
}
.login .form .btn-login:hover {
  background: white;
  color: dimgray;
  transition: 0.5s;
}
.login .forgot {
  text-decoration: none;
  color: white;
  float: right;
}
.footerSync {
  color: #136a8a;
}
.footerSync p {
  display: inline;
}
.footerSync a {
  color: green;
  text-decoration: none;
}
.footerSync a:hover {
  text-decoration: underline;
}
.footerSync .heart {
  color: #b22222;
  font-size: 1.5em;
}

.copiedMessage {
  background-color: rgb(234, 0, 30);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
}

/* PROGRESS BAR SYNC DATA CSS */

.progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 30px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: cornflowerblue;
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 12px;
  color: white;
}
.red-alert {
  background-color: rgb(234, 0, 30);
  margin-bottom: 10px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  color: white;
  font-weight: bold;
}
.choosedButton:hover .closeIcon {
  animation: rotate-center 0.6s ease-in-out both;
}
.choosedButton {
  box-shadow: 1px 2px 3px grey, inset 2px 2px 6px lightgrey;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SYNCHRO LOGIN MODAL CSS */
.modalSynchro {
  visibility: visible;
  opacity: 1;
  position: fixed;
  z-index: 5000;
  padding-top: 5vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hidden {
  display: none !important;
}

.modalSynchro_target {
  visibility: hidden !important;
  opacity: 0 !important;
  /* display: flex !important; */
}

.modalSynchro__content {
  border-radius: 4px;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  background: linear-gradient(to left, #f5f5f3, #f1f3f3);
  padding: 1em 2em;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.modalSynchroHead {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
}
.modalSynchroHead p {
  border-bottom: 1px solid lightgrey;
}
.modalSynchroHead button {
  transform: translate(-7px, -13px);
}
.modalSynchroContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modalSynchro__footer {
  text-align: center;
  margin-top: 14px;
  border-top: 1px solid lightgrey;
}
.modalSynchro__footer a {
  color: #585858;
}
.modalSynchro__footer span {
  color: #d02d2c;
}
.modalSynchro__close {
  color: red;
  font-size: 38px;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}
